.beaker-container {
    width: 180px;
    height: 180px;
}
  
.beaker {
position: absolute;
animation: beaker-boil 0.5s infinite;
-webkit-animation: beaker-boil 0.5s infinite;
-moz-animation: beaker-boil 0.5s infinite;
-o-animation: beaker-boil 0.5s infinite;
}

@keyframes beaker-boil {
0%, 25%{
box-shadow: 70px 50px 0 0 #9e9e9e, 80px 50px 0 0 #9e9e9e, 90px 50px 0 0 #9e9e9e, 100px 50px 0 0 #9e9e9e, 60px 60px 0 0 #9e9e9e, 110px 60px 0 0 #9e9e9e, 70px 70px 0 0 #9e9e9e, 100px 70px 0 0 #9e9e9e, 70px 80px 0 0 #9e9e9e, 90px 80px 0 0 #ff1717, 100px 80px 0 0 #9e9e9e, 70px 90px 0 0 #9e9e9e, 80px 90px 0 0 #ff1717, 90px 90px 0 0 #ff1717, 100px 90px 0 0 #9e9e9e, 60px 100px 0 0 #9e9e9e, 70px 100px 0 0 #ff1717, 80px 100px 0 0 #ff1717, 90px 100px 0 0 #ff1717, 100px 100px 0 0 #ff1717, 110px 100px 0 0 #9e9e9e, 50px 110px 0 0 #9e9e9e, 60px 110px 0 0 #ff1717, 70px 110px 0 0 #ff1717, 80px 110px 0 0 #ff1717, 90px 110px 0 0 #ff1717, 100px 110px 0 0 #ff1717, 110px 110px 0 0 #ee1616, 120px 110px 0 0 #9e9e9e, 40px 120px 0 0 #9e9e9e, 50px 120px 0 0 #bd0b0b, 60px 120px 0 0 #ff1717, 70px 120px 0 0 #ff1717, 80px 120px 0 0 #ee1616, 90px 120px 0 0 #ee1616, 100px 120px 0 0 #ee1616, 110px 120px 0 0 #ee1616, 120px 120px 0 0 #bd0b0b, 130px 120px 0 0 #9e9e9e, 30px 130px 0 0 #9e9e9e, 40px 130px 0 0 #bd0b0b, 50px 130px 0 0 #bd0b0b, 60px 130px 0 0 #bd0b0b, 70px 130px 0 0 #ee1616, 80px 130px 0 0 #ee1616, 90px 130px 0 0 #ee1616, 100px 130px 0 0 #ee1616, 110px 130px 0 0 #ee1616, 120px 130px 0 0 #ee1616, 130px 130px 0 0 #bd0b0b, 140px 130px 0 0 #9e9e9e, 30px 140px 0 0 #9e9e9e, 40px 140px 0 0 #bd0b0b, 50px 140px 0 0 #bd0b0b, 60px 140px 0 0 #bd0b0b, 70px 140px 0 0 #bd0b0b, 80px 140px 0 0 #bd0b0b, 90px 140px 0 0 #bd0b0b, 100px 140px 0 0 #bd0b0b, 110px 140px 0 0 #bd0b0b, 120px 140px 0 0 #bd0b0b, 130px 140px 0 0 #bd0b0b, 140px 140px 0 0 #9e9e9e, 40px 150px 0 0 #9e9e9e, 50px 150px 0 0 #9e9e9e, 60px 150px 0 0 #9e9e9e, 70px 150px 0 0 #9e9e9e, 80px 150px 0 0 #9e9e9e, 90px 150px 0 0 #9e9e9e, 100px 150px 0 0 #9e9e9e, 110px 150px 0 0 #9e9e9e, 120px 150px 0 0 #9e9e9e, 130px 150px 0 0 #9e9e9e;height: 10px; width: 10px;
}
25.01%, 50%{
box-shadow: 70px 50px 0 0 #9e9e9e, 80px 50px 0 0 #9e9e9e, 90px 50px 0 0 #9e9e9e, 100px 50px 0 0 #9e9e9e, 60px 60px 0 0 #9e9e9e, 110px 60px 0 0 #9e9e9e, 70px 70px 0 0 #9e9e9e, 100px 70px 0 0 #9e9e9e, 70px 80px 0 0 #9e9e9e, 90px 80px 0 0 #ff1717, 100px 80px 0 0 #9e9e9e, 70px 90px 0 0 #9e9e9e, 80px 90px 0 0 #ff1717, 90px 90px 0 0 #ff1717, 100px 90px 0 0 #9e9e9e, 60px 100px 0 0 #9e9e9e, 70px 100px 0 0 #bd0b0b, 80px 100px 0 0 #ff1717, 90px 100px 0 0 #ff1717, 100px 100px 0 0 #ee1616, 110px 100px 0 0 #9e9e9e, 50px 110px 0 0 #9e9e9e, 60px 110px 0 0 #bd0b0b, 70px 110px 0 0 #bd0b0b, 80px 110px 0 0 #ff1717, 90px 110px 0 0 #bd0b0b, 100px 110px 0 0 #ee1616, 110px 110px 0 0 #ee1616, 120px 110px 0 0 #9e9e9e, 40px 120px 0 0 #9e9e9e, 50px 120px 0 0 #bd0b0b, 60px 120px 0 0 #bd0b0b, 70px 120px 0 0 #bd0b0b, 80px 120px 0 0 #ee1616, 90px 120px 0 0 #ff0000, 100px 120px 0 0 #ff0000, 110px 120px 0 0 #ee1616, 120px 120px 0 0 #bd0b0b, 130px 120px 0 0 #9e9e9e, 30px 130px 0 0 #9e9e9e, 40px 130px 0 0 #bd0b0b, 50px 130px 0 0 #ff0000, 60px 130px 0 0 #ff0000, 70px 130px 0 0 #ff0000, 80px 130px 0 0 #bd0b0b, 90px 130px 0 0 #ff0000, 100px 130px 0 0 #bd0b0b, 110px 130px 0 0 #ee1616, 120px 130px 0 0 #ee1616, 130px 130px 0 0 #bd0b0b, 140px 130px 0 0 #9e9e9e, 30px 140px 0 0 #9e9e9e, 40px 140px 0 0 #bd0b0b, 50px 140px 0 0 #bd0b0b, 60px 140px 0 0 #bd0b0b, 70px 140px 0 0 #bd0b0b, 80px 140px 0 0 #bd0b0b, 90px 140px 0 0 #bd0b0b, 100px 140px 0 0 #bd0b0b, 110px 140px 0 0 #bd0b0b, 120px 140px 0 0 #bd0b0b, 130px 140px 0 0 #bd0b0b, 140px 140px 0 0 #9e9e9e, 40px 150px 0 0 #9e9e9e, 50px 150px 0 0 #9e9e9e, 60px 150px 0 0 #9e9e9e, 70px 150px 0 0 #9e9e9e, 80px 150px 0 0 #9e9e9e, 90px 150px 0 0 #9e9e9e, 100px 150px 0 0 #9e9e9e, 110px 150px 0 0 #9e9e9e, 120px 150px 0 0 #9e9e9e, 130px 150px 0 0 #9e9e9e;height: 10px; width: 10px;
}
50.01%, 75%{
box-shadow: 70px 50px 0 0 #9e9e9e, 80px 50px 0 0 #9e9e9e, 90px 50px 0 0 #9e9e9e, 100px 50px 0 0 #9e9e9e, 60px 60px 0 0 #9e9e9e, 110px 60px 0 0 #9e9e9e, 70px 70px 0 0 #9e9e9e, 80px 70px 0 0 #ee1616, 100px 70px 0 0 #9e9e9e, 70px 80px 0 0 #9e9e9e, 90px 80px 0 0 #ff1717, 100px 80px 0 0 #9e9e9e, 70px 90px 0 0 #9e9e9e, 80px 90px 0 0 #ff1717, 90px 90px 0 0 #ff1717, 100px 90px 0 0 #9e9e9e, 60px 100px 0 0 #9e9e9e, 70px 100px 0 0 #ff1717, 80px 100px 0 0 #ff1717, 90px 100px 0 0 #bd0b0b, 100px 100px 0 0 #bd0b0b, 110px 100px 0 0 #9e9e9e, 50px 110px 0 0 #9e9e9e, 60px 110px 0 0 #ff1717, 70px 110px 0 0 #ff1717, 80px 110px 0 0 #bd0b0b, 90px 110px 0 0 #ff1717, 100px 110px 0 0 #bd0b0b, 110px 110px 0 0 #bd0b0b, 120px 110px 0 0 #9e9e9e, 40px 120px 0 0 #9e9e9e, 50px 120px 0 0 #ee1616, 60px 120px 0 0 #bd0b0b, 70px 120px 0 0 #ff1717, 80px 120px 0 0 #ee1616, 90px 120px 0 0 #ee1616, 100px 120px 0 0 #ee1616, 110px 120px 0 0 #bd0b0b, 120px 120px 0 0 #bd0b0b, 130px 120px 0 0 #9e9e9e, 30px 130px 0 0 #9e9e9e, 40px 130px 0 0 #ee1616, 50px 130px 0 0 #ee1616, 60px 130px 0 0 #ee1616, 70px 130px 0 0 #ee1616, 80px 130px 0 0 #bd0b0b, 90px 130px 0 0 #ee1616, 100px 130px 0 0 #ee1616, 110px 130px 0 0 #ee1616, 120px 130px 0 0 #ee1616, 130px 130px 0 0 #bd0b0b, 140px 130px 0 0 #9e9e9e, 30px 140px 0 0 #9e9e9e, 40px 140px 0 0 #ee1616, 50px 140px 0 0 #bd0b0b, 60px 140px 0 0 #ee1616, 70px 140px 0 0 #ee1616, 80px 140px 0 0 #ee1616, 90px 140px 0 0 #bd0b0b, 100px 140px 0 0 #bd0b0b, 110px 140px 0 0 #bd0b0b, 120px 140px 0 0 #bd0b0b, 130px 140px 0 0 #bd0b0b, 140px 140px 0 0 #9e9e9e, 40px 150px 0 0 #9e9e9e, 50px 150px 0 0 #9e9e9e, 60px 150px 0 0 #9e9e9e, 70px 150px 0 0 #9e9e9e, 80px 150px 0 0 #9e9e9e, 90px 150px 0 0 #9e9e9e, 100px 150px 0 0 #9e9e9e, 110px 150px 0 0 #9e9e9e, 120px 150px 0 0 #9e9e9e, 130px 150px 0 0 #9e9e9e;height: 10px; width: 10px;
}
75.01%, 100%{
box-shadow: 70px 20px 0 0 #ff0000, 90px 30px 0 0 #ff0000, 80px 40px 0 0 #ee1616, 70px 50px 0 0 #9e9e9e, 80px 50px 0 0 #9e9e9e, 90px 50px 0 0 #9e9e9e, 100px 50px 0 0 #9e9e9e, 60px 60px 0 0 #9e9e9e, 100px 60px 0 0 #ee1616, 110px 60px 0 0 #9e9e9e, 70px 70px 0 0 #9e9e9e, 80px 70px 0 0 #ee1616, 100px 70px 0 0 #9e9e9e, 70px 80px 0 0 #9e9e9e, 90px 80px 0 0 #ff1717, 100px 80px 0 0 #9e9e9e, 70px 90px 0 0 #9e9e9e, 80px 90px 0 0 #ff1717, 90px 90px 0 0 #ff1717, 100px 90px 0 0 #9e9e9e, 60px 100px 0 0 #9e9e9e, 70px 100px 0 0 #ff1717, 80px 100px 0 0 #ff1717, 90px 100px 0 0 #bd0b0b, 100px 100px 0 0 #bd0b0b, 110px 100px 0 0 #9e9e9e, 50px 110px 0 0 #9e9e9e, 60px 110px 0 0 #ff1717, 70px 110px 0 0 #ff1717, 80px 110px 0 0 #bd0b0b, 90px 110px 0 0 #ff1717, 100px 110px 0 0 #bd0b0b, 110px 110px 0 0 #bd0b0b, 120px 110px 0 0 #9e9e9e, 40px 120px 0 0 #9e9e9e, 50px 120px 0 0 #ee1616, 60px 120px 0 0 #bd0b0b, 70px 120px 0 0 #ff1717, 80px 120px 0 0 #ee1616, 90px 120px 0 0 #ee1616, 100px 120px 0 0 #ee1616, 110px 120px 0 0 #bd0b0b, 120px 120px 0 0 #bd0b0b, 130px 120px 0 0 #9e9e9e, 30px 130px 0 0 #9e9e9e, 40px 130px 0 0 #ee1616, 50px 130px 0 0 #ee1616, 60px 130px 0 0 #ee1616, 70px 130px 0 0 #ee1616, 80px 130px 0 0 #bd0b0b, 90px 130px 0 0 #ee1616, 100px 130px 0 0 #ee1616, 110px 130px 0 0 #ee1616, 120px 130px 0 0 #ee1616, 130px 130px 0 0 #bd0b0b, 140px 130px 0 0 #9e9e9e, 30px 140px 0 0 #9e9e9e, 40px 140px 0 0 #ee1616, 50px 140px 0 0 #bd0b0b, 60px 140px 0 0 #ee1616, 70px 140px 0 0 #ee1616, 80px 140px 0 0 #ee1616, 90px 140px 0 0 #bd0b0b, 100px 140px 0 0 #bd0b0b, 110px 140px 0 0 #bd0b0b, 120px 140px 0 0 #bd0b0b, 130px 140px 0 0 #bd0b0b, 140px 140px 0 0 #9e9e9e, 40px 150px 0 0 #9e9e9e, 50px 150px 0 0 #9e9e9e, 60px 150px 0 0 #9e9e9e, 70px 150px 0 0 #9e9e9e, 80px 150px 0 0 #9e9e9e, 90px 150px 0 0 #9e9e9e, 100px 150px 0 0 #9e9e9e, 110px 150px 0 0 #9e9e9e, 120px 150px 0 0 #9e9e9e, 130px 150px 0 0 #9e9e9e;height: 10px; width: 10px;
}
}