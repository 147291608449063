:root {
  --color-surface: white;
  --color-solid: black;
  --color-primary: teal;
}

html {
  background: var(--color-surface);
  color: var(--color-solid);
}
button {
  background: var(--color-surface);
  color: var(--color-primary);
  border-color: var(--color-primary);
  padding: 8px 16px;
  font-size: 1.2rem;
  border-radius: 4px;
}

.lab-bench {
  position: fixed;
  margin-top: 200px;
  width: 100%;
  height: 300px;
  background-color: #8e8e8e;
}
